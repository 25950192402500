import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { FullComponent } from './layouts/full/full.component';
import { DefaultComponent } from './layouts/default/default.component';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { WhatsappInstanceModule } from './whatsapp-instance/whatsapp-instance.module';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    DefaultComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthModule,
    DashboardModule,
    WhatsappInstanceModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      tapToDismiss: true,
      progressAnimation: 'increasing',
      maxOpened: 1,
    }),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
