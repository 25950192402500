<div class="page-wraper">

    <!-- Content -->
    <div class="browse-job login-style3">
        <!-- Coming Soon -->
        <div class="bg-img-fix overflow-hidden bg-white">
            <div id="particles-js"></div>

            <div class="row g-0">

                <div class="center-div">

                    <div id="mCSB_1" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        style="max-height: 100%;" tabindex="0">
                        <div id="" class="container" dir="ltr">
                            <div class="login-form style-2">


                                <div class="card-body">
                                    <div class="logo-header">
                                        <a href="javascript:void(0)" class="logo custom-logo">
                                            <img src="assets/images/Logo.png" alt="" class="img-fluid">
                                        </a>
                                    </div>

                                    <nav>
                                        <div class="nav nav-tabs border-bottom-0" id="nav-tab" role="tablist">
                                            <div class="tab-content w-100" id="nav-tabContent">
                                                <div class="tab-pane fade show active" id="nav-personal" role="tabpanel"
                                                    aria-labelledby="nav-personal-tab">
                                                    <form name="form" [formGroup]="loginForm"
                                                        (ngSubmit)="isSubmit= true; go_to_login($event, loginForm.valid, loginForm.value)"
                                                        class=" dz-form pb-3">
                                                        <h3 class="form-title m-t0 text-center">Welcome to Gtech
                                                        </h3>
                                                        <div class="dz-separator-outer m-b5">
                                                            <div class="dz-separator bg-primary style-liner">
                                                            </div>
                                                        </div>
                                                        <p class="text-center mb-4">Enter your e-mail address
                                                            and
                                                            your
                                                            password. </p>
                                                        <div class="form-group mb-3">
                                                            <input formControlName="email" id="email" type="email"
                                                                class="form-control" placeholder="Email">
                                                            <span *ngIf="loginForm.get('email')?.errors && isSubmit">
                                                                <span
                                                                    *ngIf="loginForm.get('email')?.errors?.required && isSubmit"
                                                                    class="text-danger">
                                                                    Please enter email
                                                                </span>
                                                                <span
                                                                    *ngIf="loginForm.get('email')?.errors?.pattern && isSubmit"
                                                                    class="text-danger">
                                                                    Please provide a valid email address
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div class="form-group mb-3">
                                                            <input formControlName="password" id="password"
                                                                type="password" class="form-control"
                                                                placeholder="Password">
                                                            <span *ngIf="loginForm.get('password')?.errors && isSubmit">
                                                                <span
                                                                    *ngIf="loginForm.get('password')?.errors?.required && isSubmit"
                                                                    class="text-danger">
                                                                    Please enter password
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div class="form-group text-left mb-3 forget-main">
                                                            <div class="text-center bottom">
                                                                <button
                                                                    class="btn submit_btn btn-primary button-md btn-block border-0 custom-hover mb-2"
                                                                    id="nav-sign-tab">Login</button>

                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div class="form-group text-left mb-3 forget-main">

                                                        <div class="button-gn">
                                                            <!-- <button type="button" class=" custom-links-btn forget-tab "
                                                                id="nav-forget-tab" data-bs-toggle="tab"
                                                                data-bs-target="#nav-forget" type="button" role="tab"
                                                                aria-controls="nav-forget" aria-selected="false">Forget
                                                                Password
                                                                ?</button>
                                                            <br> -->


                                                            <label class="custom-links-btn forget-tab "
                                                                id="nav-forget-tab">Do not have an
                                                                account?</label>
                                                        </div>

                                                        <button class="cutom-accc" id="" data-bs-toggle="tab"
                                                            data-bs-target="#nav-sign" type="button" role="tab"
                                                            aria-controls="nav-sign" aria-selected="false">Create an
                                                            Account
                                                        </button>
                                                        <br>

                                                        <!-- <button class="cutom-accc2 "><a
                                                                href="http://whatsapp.hakimisolution.com/ReceivedMedia/video.mp4"
                                                                target="_blank" class="text-white">View
                                                                Demo</a>
                                                        </button> -->


                                                        <!-- <div class="links-footer">
                                                            <a href="#">About Us</a>
                                                            <a href="#">Privacy</a>
                                                            <a href="#">Contact Us</a>
                                                            <a href="#">Price List</a>
                                                        </div> -->

                                                    </div>



                                                </div>
                                                <div class="tab-pane fade" id="nav-forget" role="tabpanel"
                                                    aria-labelledby="nav-forget-tab">
                                                    <form class="dz-form">
                                                        <h3 class="form-title m-t0">Forget Password ?</h3>
                                                        <div class="dz-separator-outer m-b5">
                                                            <div class="dz-separator bg-primary style-liner">
                                                            </div>
                                                        </div>
                                                        <p>Enter your e-mail address below to reset your
                                                            password.
                                                        </p>
                                                        <div class="form-group mb-4">
                                                            <input name="dzName" class="form-control"
                                                                placeholder="Email Address" type="text">
                                                        </div>
                                                        <div class="form-group clearfix text-left">
                                                            <button class=" active btn btn-primary"
                                                                id="nav-personal-tab" data-bs-toggle="tab"
                                                                data-bs-target="#nav-personal" type="button" role="tab"
                                                                aria-controls="nav-personal"
                                                                aria-selected="true">Back</button>
                                                            <button class="btn btn-primary float-end">Send Me
                                                                Password</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="tab-pane fade" id="nav-sign" role="tabpanel"
                                                    aria-labelledby="nav-sign-tab">
                                                    <form class="dz-form py-2" name="form"
                                                        [formGroup]="registrationForm"
                                                        (ngSubmit)="isSubmitReg= true; go_to_registration($event, registrationForm.valid, registrationForm.value)">
                                                        <h3 class="form-title text-center">Create your free account</h3>
                                                        <div class="dz-separator-outer m-b5">
                                                            <div class="dz-separator bg-primary style-liner"></div>
                                                        </div>
                                                        <p>Enter your personal details below: </p>
                                                        <div class="form-group mt-3">
                                                            <input formControlName="name" name="name"
                                                                class="form-control" placeholder="Name" type="text">
                                                            <span
                                                                *ngIf="registrationForm.get('name')?.errors && isSubmitReg">
                                                                <span
                                                                    *ngIf="registrationForm.get('name')?.errors?.required && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please enter name
                                                                </span>
                                                                <span
                                                                    *ngIf="registrationForm.get('name')?.errors?.minlength && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please provide a valid name
                                                                </span>
                                                            </span>
                                                        </div>

                                                        <div class="form-group mt-3">
                                                            <input formControlName="email" name="email"
                                                                class="form-control" placeholder="Email" type="text">
                                                            <span
                                                                *ngIf="registrationForm.get('email')?.errors && isSubmitReg">
                                                                <span
                                                                    *ngIf="registrationForm.get('email')?.errors?.required && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please enter email
                                                                </span>
                                                                <span
                                                                    *ngIf="registrationForm.get('email')?.errors?.pattern && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please provide a valid email address
                                                                </span>
                                                            </span>
                                                        </div>

                                                        <div class="form-group mt-3">
                                                            <input formControlName="password" name="password"
                                                                class="form-control" placeholder="Password"
                                                                type="password">
                                                            <span
                                                                *ngIf="registrationForm.get('password')?.errors && isSubmitReg">
                                                                <span
                                                                    *ngIf="registrationForm.get('password')?.errors?.required && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please enter password
                                                                </span>
                                                                <span
                                                                    *ngIf="registrationForm.get('password')?.errors?.minlength && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please provide a valid password
                                                                </span>
                                                            </span>
                                                        </div>

                                                        <div class="form-group mt-3">
                                                            <input formControlName="companyName" name="companyName"
                                                                class="form-control" placeholder="Company Name"
                                                                type="text">
                                                            <span
                                                                *ngIf="registrationForm.get('companyName')?.errors && isSubmitReg">
                                                                <span
                                                                    *ngIf="registrationForm.get('companyName')?.errors?.required && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please enter company name
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div class="form-group mt-3 mb-3">
                                                            <input formControlName="mobileNumber" type="text"
                                                                id="mobileNumber" class="form-control w-100"
                                                                placeholder="Phone Number" name="mobileNumber">
                                                            <span
                                                                *ngIf="registrationForm.get('mobileNumber')?.errors && isSubmitReg">
                                                                <span
                                                                    *ngIf="registrationForm.get('mobileNumber')?.errors?.required && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please enter mobile number
                                                                </span>
                                                                <span
                                                                    *ngIf="registrationForm.get('mobileNumber')?.errors?.minlength && isSubmitReg"
                                                                    class="text-danger">
                                                                    Please provide a valid mobile number
                                                                </span>
                                                            </span>
                                                        </div>

                                                        <div class="mb-5">
                                                            <!-- <span class="form-check float-start mb-4 ">
                                                                <input formControlName="termsAndCondition"
                                                                    type="checkbox" class="form-check-input" id="check2"
                                                                    name="example1">
                                                                <span class="form-check-label d-unset sets"
                                                                    for="check2">I agree to the
                                                                </span>
                                                                <span class="mb-0 "><a href="#" class="sets2">Terms
                                                                        of
                                                                        Service
                                                                    </a><a href="#" class="sets2">&amp; Privacy
                                                                        Policy</a></span>

                                                            </span> -->

                                                        </div>
                                                        <!-- <span
                                                            *ngIf="registrationForm.get('termsAndCondition')?.errors && isSubmitReg">
                                                            <span
                                                                *ngIf="registrationForm.get('termsAndCondition')?.errors?.required && isSubmitReg"
                                                                class="text-danger">
                                                                Please agree terms & condition
                                                            </span>
                                                        </span> -->
                                                        <div class="form-group clearfix text-left mt-4">
                                                            <button class="btn btn-primary outline gray"
                                                                data-bs-toggle="tab" data-bs-target="#nav-personal"
                                                                type="button" role="tab" aria-controls="nav-personal"
                                                                aria-selected="true">Back</button>
                                                            <button
                                                                class="btn btn-primary submit_btn_reg float-end">Submit</button>
                                                        </div>
                                                    </form>

                                                </div>


                                            </div>

                                        </div>
                                    </nav>
                                </div>


                            </div>
                        </div>
                        <div id="mCSB_1_scrollbar_vertical"
                            class="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                            style="display: block;">
                            <div class="mCSB_draggerContainer">
                                <div id="mCSB_1_dragger_vertical" class="mCSB_dragger"
                                    style="position: absolute; min-height: 0px; display: block; height: 652px; max-height: 643px; top: 0px;">
                                    <div class="mCSB_dragger_bar" style="line-height: 0px;"></div>
                                    <div class="mCSB_draggerRail"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
        <!-- Full Blog Page Contant -->
    </div>
    <!-- Content END-->
</div>