<div class="breadcumb-custom">
    <ol class="breadcrumb">

        <li>
            <a href="./index.html">Home / </a>
        </li>

        <li>
            <a href="javascript:void(0)">WhatsApp Instances / </a>
        </li>



        <li class="active">
            <strong>Create Instances</strong>
        </li>

    </ol>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Instance</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="instance-desc">
                                <h2>To send and receive messages, you have to authorize our server like for
                                    WhatsApp Web.</h2>
                                <ul>
                                    <li>Open the WhatsApp app on your phone</li>
                                    <li>Press Settings->Linked Device and Link a Device</li>
                                    <li>Scan a code and wait a minute</li>
                                    <li>Keep your phone turned on and connected to the Internet</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div>
                                <h4 id="waMessage"></h4>
                            </div>
                            <div class="status">
                                <span class="activeConnection" style="display:none">
                                    <b>Bearer Token:- {{loginId}}</b>
                                    <!-- <button (click)="waLogout()" class="btn btn-danger">Logout</button> -->
                                </span>
                                <span class="lostConnection" style="display:none">
                                    <button (click)="initClient()" class="btn btn-success">Connect Now</button>
                                </span>
                            </div>
                            <div class="qr-code">
                                <img id="qrcode" style="display:none" src="" class="img-fluid" width="200" height="200">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="instance-desc mb-4">
                                <h2>WhatsApp blocking / banning - Disclaimer:</h2>
                                <p>WhatsApp monitors closely the type of message you send. Sending
                                    unsolicited messages (SPAM) that your recipients are not expecting or
                                    want to receive, can result in WhatsApp permanently banning your number
                                    or IP, this is an action performed by Whatsapp and the system has no
                                    control or responsibility over it.</p>
                                <ul>
                                    <li>Some cases where we are certain that your number may get banned are:
                                    </li>
                                    <li>Sending messages to people that have not agreed to receive messages
                                        from you or that have no connection to you or your business.</li>
                                    <li>Sending messages to numbers that do not exist or that do not have
                                        WhatsApp installed.</li>
                                    <li>Sending messages for debt recovery (people will report it as SPAM).
                                    </li>
                                    <li>Sending message from freshly registered number on whatsapp.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>