import { Component, OnInit } from '@angular/core';
import { io } from "socket.io-client";
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { DefaultComponent } from '../../layouts/default/default.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EncryptionService } from '../../services/encryption.service';
declare var $: any;
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  public socket_api: any;
  public loginData: any = ''
  public loginId: any = ''
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private DefaultComponent: DefaultComponent,
    private EncryptionService: EncryptionService,
  ) {
    if (!localStorage.getItem('gt_login_id')) {
      this.router.navigate(['/']);
    }
    var th = this;
    this.loginData = JSON.parse(localStorage.getItem('gt_login_data'))
    this.loginId = localStorage.getItem('gt_login_id');
    if (!this.socket_api) {
      this.socket_api = io(environment.socketUrl, { query: { user_id: this.loginId } });
    }

    this.socket_api.on('remove-session', function (id) {
      console.log('remove-session', id)
    });
    this.socket_api.on('message', function (data) {
      $("#waMessage").html(data.text);
      if (data.status == 0) {
        $(".activeConnection").hide();
        $(".lostConnection").show();
      } else if (data.status == 1) {
        $("#qrcode").hide();
        $(".lostConnection").hide();
        $(".activeConnection").show();
      } else {
        $(".lostConnection").hide();
        $(".activeConnection").hide();
      }
      console.log('message', data.id + " - " + data.text)
    });

    this.socket_api.on('qr', (data) => {
      console.log('qr', data)
      $("#qrcode").show();
      $("#qrcode").attr("src", data.src);

    })
    this.socket_api.on('authenticated', function (data) {
      $("#qrcode").hide();
      // $("#qrcode").remove();
      //this.socket_api.off('qr');
    });

    this.socket_api.on('ready', (data) => {
      $("#qrcode").hide();
      // $("#qrcode").remove();
      //this.socket_api.off('qr');
    });
    setTimeout(() => {
      this.checkClientConnection();
    }, 500);
  }
  checkClientConnection() {
    var clientId = this.loginId;
    var th = this;
    this.DefaultComponent.loaderShow();
    var parameter = { clientId: clientId }
    const payload = { data: this.EncryptionService.convert_parameter(parameter) }
    this.http.post<any>(`${environment.frontApiUrl}checkClientConnection`, payload).subscribe((resp: any) => {
      this.DefaultComponent.loaderHide();

    }, (err) => {

      th.DefaultComponent.loaderHide();
      th.toastr.error(err.error.message, 'Error');
    });
  }
  waLogout() {
    var clientId = this.loginId;
    var th = this;
    this.DefaultComponent.loaderShow();
    var parameter = { clientId: clientId }
    const payload = { data: this.EncryptionService.convert_parameter(parameter) }
    this.http.post<any>(`${environment.frontApiUrl}clientLogout`, payload).subscribe((resp: any) => {
      this.DefaultComponent.loaderHide();
      if (resp.status) {
        $(".activeConnection").hide();
        $(".lostConnection").show();
        th.toastr.success(resp.message, 'Success');
      } else {
        th.toastr.error(resp.message, 'Error');
      }
    }, (err) => {
      this.DefaultComponent.loaderHide();
      th.toastr.error(err.error.message, 'Error');
    });
  }
  initClient() {
    var clientId = this.loginId;
    var th = this;
    this.DefaultComponent.loaderShow();
    var parameter = { clientId: clientId }
    const payload = { data: this.EncryptionService.convert_parameter(parameter) }
    this.http.post<any>(`${environment.frontApiUrl}initClient`, payload).subscribe((resp: any) => {
      th.DefaultComponent.loaderHide();
    }, (err) => {
      th.DefaultComponent.loaderHide();
      th.toastr.error(err.error.message, 'Error');
    });
  }
  sendMessage() {
    var th = this;
    var clientId = this.loginId;
    this.DefaultComponent.loaderShow();
    this.http.post<any>(`${environment.frontApiUrl}send_message`, { clientId: clientId, mobileNumber: '9687351441', message: 'Hello from angular!!' }).subscribe((resp: any) => {
      th.DefaultComponent.loaderHide();

    }, (err) => {
      th.DefaultComponent.loaderHide();
      th.toastr.error(err.error.message, 'Error');
    });
  }
  ngOnInit(): void {
  }

}
