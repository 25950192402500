import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScriptService } from '../../services/script.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, NgModel, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { EncryptionService } from '../../services/encryption.service';
import { FullComponent } from '../../layouts/full/full.component';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  registrationForm: FormGroup;
  public isSubmit = false;
  public isSubmitReg = false;
  constructor(
    private ScriptService: ScriptService,
    private EncryptionService: EncryptionService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private AuthService: AuthService,
    private FullComponent: FullComponent
  ) {
    if (localStorage.getItem('gt_login_id')) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit(): void {
    this.ScriptService.load('intlTelInput', 'particles');
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: ['', Validators.required],
    });
    this.registrationForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: ['', [Validators.required, Validators.minLength(5)]],
      companyName: ['', Validators.required],
      // termsAndCondition: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ScriptService.load('init')
    }, 500);
  }
  go_to_login(event: Event, isValid: Boolean, formDetail: any) {
    var th = this;
    if (isValid) {
      th.FullComponent.loaderShow();
      var routers = this.router;
      $('.submit_btn').attr('disabled', true);
      var parameter = th.loginForm.value;
      th.AuthService.Login(parameter).subscribe((resp: any) => {
        var dec_data = this.EncryptionService.decrypt_data(resp.data)
        if (dec_data) {
          th.FullComponent.loaderHide();
          var res = JSON.parse(dec_data)
          if (res.status) {
            localStorage.setItem('gt_login_id', res.user_data._id);
            localStorage.setItem('gt_login_data', JSON.stringify(res.user_data));
            localStorage.setItem('gt_login_name', res.user_data.u_name);
            $('.submit_btn').attr('disabled', false);
            this.toastr.success(res.message, 'Success');
            routers.navigate(['/dashboard']);
          } else {
            $('.submit_btn').attr('disabled', false);
            this.toastr.error(res.message, 'Error')
          }
        } else {
          th.FullComponent.loaderHide();
          $('.submit_btn').attr('disabled', false);
          this.toastr.error('Something went wrong', 'Error')
        }
      }, (err) => {
        th.FullComponent.loaderHide();
        $('.submit_btn').attr('disabled', false);
        this.toastr.error(err.error.message, 'Error');
      });


    }
  }
  go_to_registration(event: Event, isValid: Boolean, formDetail: any) {
    var th = this;
    if (isValid) {
      th.FullComponent.loaderShow();
      var routers = this.router;
      $('.submit_btn_reg').attr('disabled', true);
      var parameter = th.registrationForm.value;
      th.AuthService.Registration(parameter).subscribe((resp: any) => {
        var dec_data = this.EncryptionService.decrypt_data(resp.data)
        if (dec_data) {
          th.FullComponent.loaderHide();
          var res = JSON.parse(dec_data)
          if (res.status) {
            $('.submit_btn_reg').attr('disabled', false);
            this.toastr.success(res.message, 'Success');
            location.reload();
          } else {
            $('.submit_btn_reg').attr('disabled', false);
            this.toastr.error(res.message, 'Error')
          }
        } else {
          th.FullComponent.loaderHide();
          $('.submit_btn_reg').attr('disabled', false);
          this.toastr.error('Something went wrong', 'Error')
        }
      }, (err) => {
        th.FullComponent.loaderHide();
        $('.submit_btn_reg').attr('disabled', false);
        this.toastr.error(err.error.message, 'Error');
      });


    }
  }
}
