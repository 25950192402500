<div class="breadcumb-custom">
    <ol class="breadcrumb">

        <li>
            <a routerLink="/dashboard">Home / </a>
        </li>



        <li class="active">
            <strong>Whatsapp Instances</strong>
        </li>

    </ol>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">WhatsApp Instances</h4>
                </div>
                <div class="card-body">
                    <div class="basic-form pt-0">


                        <a id="btnAddTemplate" tabindex="0" class="btn btn-primary" href="CreateInstancebeta.html"><i
                                class="fa fa-plus"></i>&nbsp; Add
                            Instances</a>

                    </div>
                </div>
                <div class="table-responsive pt-2">
                    <div class="table-responsive p-3">
                        <table id="example4" class="display table  table-striped dataTable no-footer table-bordered">
                            <thead class="thead-info">
                                <tr role="row">

                                    <th>Settings</th>
                                    <th>View</th>
                                    <th>Instance</th>
                                    <th>Unique Id</th>
                                    <th>Remain</th>
                                    <th>Sent</th>
                                    <th>Sent (last 10 Mins)</th>
                                    <th>Validity Till</th>
                                    <th>Connectivity Status</th>
                                    <th colspan="2">Action</th>
                                </tr>
                            </thead>
                            <tbody class="text-center">

                                <tr>
                                    <td><a href="InstanceSetting.html"><i class="fa fa-gears fa-2x"></i></a>
                                    </td>
                                    <td><a href=""><i class="fa fa-desktop fa-2x"></i></a></td>
                                    <td><a href=""><i class="fa-brands fa-whatsapp "></i>
                                            +91-9090909090</a>
                                    </td>
                                    <td>123-456-89899</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>31 jan 2024</td>
                                    <td> <a href="javascript:void(0)"> <span class="badge badge-success">connected
                                            </span></a>
                                    </td>
                                    <td>
                                        <div class="">
                                            <button type="button" class="btn btn-primary"><i
                                                    class="fa-solid fa-power-off me-2"></i>Logout</button>
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#wslinks"><i class="fa-solid fa-link me-2"></i>WhatsApp
                                                Links</button>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>