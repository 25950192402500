import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WhatsappInstanceRoutingModule } from './whatsapp-instance-routing.module';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';


@NgModule({
  declarations: [CreateComponent, ListComponent],
  imports: [
    CommonModule,
    WhatsappInstanceRoutingModule
  ]
})
export class WhatsappInstanceModule { }
