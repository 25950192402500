import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from '../layouts/default/default.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: 'create-instance',
    component: CreateComponent
  }, {
    path: 'instance-list',
    component: ListComponent
  }]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WhatsappInstanceRoutingModule { }
