import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EncryptionService } from './encryption.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  serverUrl = environment;
  constructor(
    private http: HttpClient,
    private EncryptionService: EncryptionService
  ) { }
  Login(parameter) {
    const payload = { data: this.EncryptionService.convert_parameter(parameter) }
    return this.http.post<any>(`${this.serverUrl.clientApiUrl}login`, payload);
  }
  Registration(parameter) {
    const payload = { data: this.EncryptionService.convert_parameter(parameter) }
    return this.http.post<any>(`${this.serverUrl.clientApiUrl}register`, payload);
  }
}
