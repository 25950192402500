import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ScriptService } from '../../services/script.service';
declare var $: any;
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit, AfterViewInit {
  public loginData: any = ''
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private ScriptService: ScriptService
  ) {
    if (!localStorage.getItem('gt_login_id')) {
      this.router.navigate(['/']);
    }
    this.loginData = JSON.parse(localStorage.getItem('gt_login_data'))
  }
  loaderShow() {
    this.spinner.show();
  }
  loaderHide() {
    this.spinner.hide();
  }
  ngOnInit(): void {
  }
  logout() {
    localStorage.removeItem('gt_login_id');
    localStorage.removeItem('gt_login_name');
    localStorage.removeItem('gt_login_data');
    this.router.navigate(['/']);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.ScriptService.load('custom', 'deznav', 'demo');
    }, 1000);
    // $('.resendButton').attr('disabled', true);
    // $('.resendButton').css('cursor', 'not-allowed');
  }
}
