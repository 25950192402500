import { Injectable } from '@angular/core';
declare var $: any;

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [

  { name: 'intlTelInput', src: 'assets/js/intlTelInput-jquery.min.js' },
  { name: 'particles', src: 'assets/js/particles.min.js' },
  { name: 'init', src: 'assets/js/init.js' },
  { name: 'custom', src: 'assets/js/custom.js' },
  { name: 'demo', src: 'assets/js/demo.js' },
  { name: 'deznav', src: 'assets/js/deznav-init.js' },
];
@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: any = {};
  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      // if (this.scripts[name].loaded) {
      //   console.log(this.scripts[name]);
      //   resolve({ script: name, loaded: true, status: 'Already Loaded' });
      // } else {
      // load script

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.scripts[name].src;
      script.onload = () => {
        this.scripts[name].loaded = true;
        resolve({ script: name, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
      // }
    });
  }
}
